<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-pencil-outline</v-icon>委託・消化仕入実績入力</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-container style="max-width: inherit">
          <ag-grid-vue
            id="CartList"
            class="ag-theme-alpine"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :alwaysShowHorizontalScroll="true"
            :pagination="true"
            :paginationPageSize="selectedPageSize"
            :suppressCsvExport="false"
            :suppressExcelExport="true"
            :localeText="localeText"
            :columnTypes="columnTypes"
            :frameworkComponents="frameworkComponents"
            rowSelection="single"
            @grid-ready="onGridReady"
            style="height: 500px"
          >
          </ag-grid-vue>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">確定</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, EditableColumn } from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import { NumericCellEditor } from "../../components/ag-grid/cellEditors";

export default {
  name: "PropositionResultEntry",
  props: ["resultEntryModel"],
  data() {
    return {
      columnDefs: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isDecimal: Validation.isDecimal,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
      },
      rowData: null,
      defaultColDef: null,
      gridApi: null,
      columnApi: null,
      defaultCsvExportParams: null,
      detailSelected: 0,
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpEditableColumn: EditableColumn,
      },
      frameworkComponents: {
        dpNumericCellEditor: NumericCellEditor,
      },
      errorColmuns: [
        { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
    };
  },
  components: {
    AgGridVue,
    ErrorGridDialog,
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
    },
    onOpen() {
      console.log(this.resultEntryModel.rows);
      this.rowData = JSON.parse(JSON.stringify(this.resultEntryModel.rows));
    },
    async onUpdateClick() {
      if (this.validate(this.rowData)) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.rowData.forEach((row) => {
            updateModels.push({
              propositionDetailId: row.propositionDetailId,
              returnQuantity: Number(row.returnQuantity),
              salesResults: Number(row.salesResults),
              updateDatetime: row.updateDate,
            });
          });
          var requestContents = {
            propositionDetails: updateModels,
          };
          const result = await this.$store.dispatch("propositionDetail/resultEntry", requestContents);

          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    propositionDetailId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入実績入力", records: errorRows });
                this.$dialog.notify.error(`委託・消化仕入実績登録に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`実績を登録しました (${result.data?.contents.countQuantity}件)`, {
                  timeout: 2300,
                });
                this.$emit("onEntrySubmit", result.data?.contents.propositionDetails);
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CorporationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate(rows) {
      let isValid = true;
      let errorRows = [];
      rows.forEach((row) => {
        var error = this.onValidGrid(row);
        if (error != true) {
          errorRows.push({
            propositionDetailId: row.propositionDetailId,
            errorMessage: error,
          });
          isValid = false;
        }
      });
      if (!isValid) {
        this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入実績入力", records: errorRows });
        this.$dialog.notify.error(`入力エラーがあります。ご確認ください。`, {
          timeout: 2300,
        });
      }
      return isValid;
    },
    onValidGrid(row) {
      var ret = true;
      var messages = [];

      // 返品数
      this.setValidMessage(this.rules.required(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isNumber(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isMinNumber(0)(row.returnQuantity), "返品数", messages);
      // 販売実績数
      this.setValidMessage(this.rules.required(row.salesResults), "販売実績数", messages);
      this.setValidMessage(this.rules.isNumber(row.salesResults), "販売実績数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.salesResults), "販売実績数", messages);
      this.setValidMessage(this.rules.isMinNumber(0)(row.salesResults), "販売実績数", messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultColDef = {
      filter: false,
      resizable: true,
      sortable: false,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    if (this.isCafereoUser) {
      this.columnDefs = [
        { field: "propositionDetailId", headerName: "委託・消化仕入明細ID", pinned: "left" },
        {
          headerName: "販売実績数",
          field: "salesResults",
          cellEditor: "dpNumericCellEditor",
          type: ["dpNumericColumn", "dpEditableColumn"],
          pinned: "left",
        },
        {
          headerName: "返品数",
          field: "returnQuantity",
          cellEditor: "dpNumericCellEditor",
          type: ["dpNumericColumn", "dpEditableColumn"],
          pinned: "left",
        },
        { field: "propositionId", headerName: "委託・消化仕入ID" },
        { field: "propositionName", headerName: "案件名" },
        { field: "janCode", headerName: "委託JANCODE" },
        { field: "title", headerName: "委託タイトル" },
        { field: "productName", headerName: "委託商品名" },
        { headerName: "委託数", field: "entrustQuantity", type: "dpNumericColumn" },
        { headerName: "メーカー検品数", field: "makerInspectionQuantity", type: "dpNumericColumn" },
        { field: "orderId", headerName: "受注ID" },
      ];
    } else {
      this.columnDefs = [
        { field: "propositionDetailId", headerName: "委託・消化仕入ID", pinned: "left" },
        {
          headerName: "販売実績数",
          field: "salesResults",
          cellEditor: "dpNumericCellEditor",
          type: ["dpNumericColumn", "dpEditableColumn"],
          pinned: "left",
        },
        {
          headerName: "返品数",
          field: "returnQuantity",
          cellEditor: "dpNumericCellEditor",
          type: ["dpNumericColumn", "dpEditableColumn"],
          pinned: "left",
        },
        {
          headerName: "検品数",
          field: "inspectionQuantity",
          type: ["dpNumericColumn", "dpEditableColumn"],
          pinned: "left",
        },
        { field: "propositionName", headerName: "案件名" },
        { field: "janCode", headerName: "委託JANCODE" },
        { field: "productName", headerName: "委託商品名" },
        { headerName: "委託数", field: "entrustQuantity", type: "dpNumericColumn" },
        { colId: "inspectionResult", headerName: "検品結果確定" },
        { headerName: "メーカー検品数", field: "makerInspectionQuantity", type: "dpNumericColumn" },
        { colId: "makerInspectionResult", headerName: "メーカー検品結果確定" },
        { headerName: "検品結果承認可否", field: "inspectionApprovalResult" },
        { headerName: "検品結果承認者", field: "inspectionApprovalUser" },
        { headerName: "検品結果承認日時", field: "inspectionApprovalDateTime" },
        { field: "orderNumber", headerName: "受注ID" },
      ];
    }
    this.onOpen(this.resultEntryModel);
  },
};
</script>
