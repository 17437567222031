<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-book-arrow-down</v-icon>委託申請書出力</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-container style="max-width: inherit">
          <ag-grid-vue
            id="ExportList"
            :domLayout="domLayout"
            class="ag-theme-alpine"
            :defaultColDef="defaultColDef"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :alwaysShowHorizontalScroll="true"
            :pagination="true"
            :localeText="localeText"
            :columnTypes="columnTypes"
            rowSelection="single"
            @grid-ready="onGridReady"
          >
          </ag-grid-vue>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">閉じる</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">出力</v-btn>
    </v-card-actions>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import ExportStockOwners from "../../consts/ExportStockOwners";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { EditableColumn } from "../../models/ag-grid/columnTypes";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "ApplicationFormExport",
  props: ["inputData"],
  components: { AgGridVue, ErrorGridDialog },
  data() {
    return {
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
      },
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultCsvExportParams: null,
      detailSelected: 0,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
      columnDefs: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        {
          headerName: "在庫所有元",
          editable: true,
          width: 170,
          field: "stockOwner",
          type: "dpEditableColumn",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ExportStockOwners.all().map((stockOwner) => {
              return stockOwner.text;
            }),
          },
        },
      ],
      columnTypes: {
        dpEditableColumn: EditableColumn,
      },
      errorColmuns: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
    };
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.initData(this.inputData);
  },
  watch: {
    selectedPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    inputData(inputData) {
      this.initData(inputData);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    initData(inputData) {
      this.rowData = JSON.parse(JSON.stringify(inputData));
    },
    getUpdateModel() {
      return this.updateModel;
    },
    async onUpdateClick() {
      try {
        this.loadingOn();
        let updateModels = [];
        let errorRows = [];
        let isValid = false;
        this.gridApi.forEachNode((row) => {
          let check = this.rules.required(row.data.stockOwner);
          if (check !== true) {
            errorRows.push({
              propositionId: row.data.propositionId,
              errorMessage: ["在庫所有元は、" + check],
            });
            isValid = true;
          }
          if (!isValid) {
            updateModels.push({
              propositionId: row.data.propositionId,
              stockOwner: ExportStockOwners.textOf(row.data.stockOwner).toString(),
            });
          }
        });

        if (isValid) {
          this.$refs.bulkErrorGrid.open({ title: "委託申請書出力", records: errorRows });
          this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
          return;
        }
        var requestContents = {
          exportConditions: updateModels,
        };
        const result = await this.$store.dispatch("proposition/print", requestContents);

        let error = result.data?.header;

        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
            // 更新分のレコード変更
            window.open(result.data.contents.exportFilePath, "_blank");
            this.$dialog.notify.info(`委託申請書を出力しました`, {
              timeout: 2300,
            });
            this.$emit("onDialogClose");
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("PropositionSearch::onBtnInspectApproval", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
