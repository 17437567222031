<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title v-if="allowedAction(constants.cafereoActions)"
            ><v-icon>mdi-folder-search</v-icon>委託・消化商品管理</v-app-bar-title
          >
          <v-app-bar-title v-if="allowedAction(constants.supplierActions)"
            ><v-icon>mdi-folder-search</v-icon>委託・消化仕入一覧</v-app-bar-title
          >
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
            <search-conditions v-if="allowedAction(constants.cafereoActions)" @search="onBtnSearch" max-height="100%">
              <dp-date-picker
                v-model="searchModel.termFrom"
                class="mx-2"
                label="納品日"
                dense
                type="date"
                :rules="[rules.termFrom]"
              ></dp-date-picker>
              <dp-date-picker
                v-model="searchModel.termTo"
                class="mx-2"
                label="返品予定日"
                dense
                type="date"
                :rules="[rules.termTo]"
              ></dp-date-picker>
              <v-text-field
                v-model="searchModel.propositionDetailId"
                :rules="[rules.maxLength(12)]"
                class="ma-4"
                label="明細ID"
                dense
              ></v-text-field>
              <v-text-field
                v-model="searchModel.corpName"
                :rules="[rules.maxLength(150)]"
                class="ma-4"
                label="法人名"
                dense
              ></v-text-field>
            </search-conditions>
            <search-conditions v-if="allowedAction(constants.supplierActions)" @search="onBtnSearch" max-height="100%">
              <dp-date-picker
                v-model="searchModel.termFromFrom"
                class="mx-2"
                label="納品日From"
                dense
                type="date"
                :rules="[rules.termFromFrom]"
              ></dp-date-picker>
              <dp-date-picker
                v-model="searchModel.termFromTo"
                class="mx-2"
                label="納品日To"
                dense
                type="date"
                :rules="[rules.termFromTo]"
              ></dp-date-picker>
              <dp-date-picker
                v-model="searchModel.termToFrom"
                class="mx-2"
                label="返品予定日From"
                dense
                type="date"
                :rules="[rules.termToFrom]"
              ></dp-date-picker>
              <dp-date-picker
                v-model="searchModel.termToTo"
                class="mx-2"
                label="返品予定日To"
                dense
                type="date"
                :rules="[rules.termToTo]"
              ></dp-date-picker>
              <v-text-field
                v-model="searchModel.propositionDetailId"
                class="mx-2"
                :rules="[rules.maxLength(12)]"
                label="明細ID"
                dense
              ></v-text-field>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <template v-if="allowedAction(constants.cafereoActions)">
            <tooltip-icon-button
              :disabled="!isSanction"
              icon="mdi-check"
              @click="onBtnApproval"
              v-if="allowedAction(constants.handlingApprovalActions)"
              >取り扱い承認</tooltip-icon-button
            >
            <confirm-grid-dialog
              ref="confirmGrid"
              width="1100px"
              height="350px"
              :autoClose="false"
            ></confirm-grid-dialog>
            <tooltip-icon-button
              :disabled="selectionRows.length == 0"
              icon="mdi-draw"
              @click="onBtnInspectApproval"
              v-if="allowedAction(constants.resultApprovalActions)"
              >検品結果承認</tooltip-icon-button
            >
            <confirm-grid-dialog
              ref="inspectConfirmGrid"
              width="1100px"
              height="350px"
              :autoClose="false"
            ></confirm-grid-dialog>
            <v-divider vertical></v-divider>
            <tooltip-icon-button
              icon="mdi-pencil-plus-outline"
              @click="onEntryClick"
              v-if="allowedAction(constants.createActions)"
              >委託・消化仕入追加</tooltip-icon-button
            >
            <v-divider vertical></v-divider>
            <tooltip-icon-button
              icon="mdi-pencil-outline"
              @click="onEditClick"
              :disabled="!selectedRow"
              v-if="allowedAction(constants.updateActions)"
              >委託・消化仕入編集</tooltip-icon-button
            >
            <tooltip-icon-button
              icon="mdi-pencil-remove"
              @click="onRemoveClick"
              :disabled="this.selectionRows.length === 0"
              v-if="allowedAction(constants.deleteActions)"
              >委託・消化仕入削除</tooltip-icon-button
            >
            <v-dialog v-model="editDialog" max-width="800px" persistent scrollable>
              <proposition-entry
                :inputModel="propositionModel"
                @onDialogClose="onDialogClose"
                @onEntrySubmit="onEntrySubmit"
                @onEditSubmit="onEditSubmit"
                v-if="editDialog"
              ></proposition-entry>
            </v-dialog>
            <tooltip-icon-button
              :disabled="!selectedRow"
              icon="mdi-book-arrow-down"
              @click="onBtnApplicationForm"
              v-if="allowedAction(constants.printActions)"
              >委託申請書出力</tooltip-icon-button
            >
            <v-dialog v-model="printDialog" max-width="800px" persistent scrollable>
              <application-form-export
                ref="applicationFormExport"
                :inputData="selectionRows"
                @onDialogClose="onPrintDialogClose"
                v-if="printDialog"
              ></application-form-export>
            </v-dialog>
          </template>
          <tooltip-icon-button
            v-if="allowedAction(constants.createDetailActions)"
            :disabled="!selectedRow"
            icon="mdi-pencil-plus-outline"
            @click="onEntryClickDetails()"
            >委託・消化仕入明細追加</tooltip-icon-button
          >
          <v-dialog v-model="editDetailDialog" max-width="800px" persistent scrollable>
            <proposition-detail-entry
              :inputModel="propositionDetailModel"
              :inputHeader="selectedRow"
              :propositionList="propositionList"
              @onDialogClose="onDialogCloseDetails"
              @onEntrySubmit="onEntrySubmitDetails"
              @onEditSubmit="onEditSubmitDetails"
              v-if="editDetailDialog"
            ></proposition-detail-entry>
          </v-dialog>
          <tooltip-icon-button
            icon="mdi-import"
            @click="onBtnResultImport"
            :disabled="!selectedRow"
            v-if="allowedAction(constants.detailAchievementInputActions)"
            >委託・消化仕入明細実績取込</tooltip-icon-button
          >
          <v-dialog v-model="resultImportDialog.show" :max-width="resultImportDialog.width" persistent scrollable>
            <proposition-result-import
              :propositionDetailList="propositionDetailList"
              :corporateCode="selectedRow ? selectedRow.corporateCode : null"
              @complete="onResultImportComplete"
              @cancel="onResultImportDialogClose"
              @expand="resultImportDialog.width = $event ? '100%' : '800px'"
              v-if="resultImportDialog.show"
            ></proposition-result-import>
          </v-dialog>
          <tooltip-icon-button
            icon="mdi-database-import"
            @click="onBtnImport"
            :disabled="!selectedRow"
            v-if="allowedAction(constants.detailAchievementInputActions)"
            >明細一括取り込み</tooltip-icon-button
          >
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <detail-import
              :propositionModel="selectedRow"
              @complete="onImportComplete"
              @cancel="onImportDialogClose"
              @expand="importDialog.width = $event ? '100%' : '800px'"
              v-if="importDialog.show"
            ></detail-import>
          </v-dialog>
          <tooltip-icon-button icon="mdi-download" @click="onExportClick">CSVダウンロード</tooltip-icon-button>
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider v-if="allowedAction(constants.cafereoActions)" vertical></v-divider>
          <template>
            <tooltip-icon-button
              v-if="allowedAction(['C020410'])"
              icon="mdi-pencil-outline"
              @click="onEditClickDetails()"
              :disabled="!selectedDetailRow"
              >委託・消化仕入明細編集</tooltip-icon-button
            >
            <tooltip-icon-button
              v-if="allowedAction(['C020411'])"
              icon="mdi-pencil-remove"
              @click="onRemoveClickDetails"
              :disabled="selectionDetailRows.length == 0"
              >委託・消化仕入明細削除</tooltip-icon-button
            >
            <tooltip-icon-button
              v-if="allowedAction(['C020412'])"
              icon="mdi-plus-minus-variant"
              @click="onBtnResultEntry()"
              :disabled="!(selectionDetailRows.length > 0)"
              >委託・消化仕入実績入力</tooltip-icon-button
            >
            <v-dialog v-model="resultEntryDialog" max-width="1800px" persistent scrollable>
              <proposition-result-entry
                ref="propositionResultEntry"
                :resultEntryModel="resultEntryModel"
                @onDialogClose="onResultDialogClose"
                @onEntrySubmit="onResultEntrySubmit"
                v-if="resultEntryDialog"
              ></proposition-result-entry>
            </v-dialog>
          </template>
          <tooltip-icon-button icon="mdi-download" @click="onExportClickDetails">CSVダウンロード</tooltip-icon-button>
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFiltersDetail()"
            >明細フィルター解除</tooltip-icon-button
          >
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row no-gutters dense>
          <v-col class="text-h5" cols="12">
            <v-card-subtitle style="display: flex; height: 40px" class="text-h5">■案件 </v-card-subtitle>
          </v-col>
          <v-col cols="12" :style="gridStyle">
            <ag-grid-vue
              :gridOptions="gridOptions"
              :rowData="propositionRecords"
              class="ag-theme-alpine"
              @selection-changed="onSelectionChanged"
              style="height: 100%"
            ></ag-grid-vue>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6" dense style="margin-top: 0px !important">
          <v-col class="text-h5 mt-5" cols="12">
            <v-card-subtitle style="display: flex; height: 40px" class="text-h5">
              ■明細
              <v-spacer></v-spacer>
            </v-card-subtitle>
          </v-col>
          <v-row v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="2">
              <v-card elevation="1" class="mt-2">
                <v-card-text class="font-weight-bold ml-4 mb-4">
                  委託数合計：{{ this.entrustQuantityTotal | comma }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card elevation="1" class="mt-2">
                <v-card-text class="font-weight-bold ml-4 mb-4">
                  検品数合計：{{ this.inspectionQuantityTotal | comma }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card elevation="1" class="mt-2">
                <v-card-text class="font-weight-bold ml-4 mb-4">
                  販売実績数合計：{{ this.salesResultsTotal | comma }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card elevation="1" class="mt-2">
                <v-card-text class="font-weight-bold ml-4 mb-4">
                  返品数合計：{{ this.returnQuantityTotal | comma }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card elevation="1" class="mt-2">
                <v-card-text class="font-weight-bold ml-4 mb-4">
                  選択中明細合計：{{ this.selectionDetailRows.length }}件
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="2">
              <v-card elevation="1" class="mt-2">
                <v-card-text class="font-weight-bold ml-4 mb-4">
                  売立合計金額：{{ this.soldTotalPriceTotal | comma }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-col cols="12" :style="gridStyle">
            <ag-grid-vue
              :gridOptions="gridOptionsDetail"
              :rowData="propositionDetailRecords"
              class="ag-theme-alpine"
              @selection-changed="onSelectionChangedDetails"
              @filter-changed="onfilterChanged"
              style="height: 100%"
            ></ag-grid-vue>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="infoMaximum ? 12 : 3" v-if="shownInfo" style="flex-basis: auto; display: flex">
        <v-divider vertical></v-divider>
        <proposition-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :detailDetails="selectedDetailDetails"
          :propositionId="selectPropositionId"
          :propositionDetailId="selectPropositionDetailId"
        ></proposition-infos>
      </v-col>
    </v-row>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
    <error-grid-dialog
      ref="detailBulkErrorGrid"
      width="80%"
      height="80%"
      :columns="errorColmunDetails"
    ></error-grid-dialog>
  </v-container>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { ofType as RoleOfType } from "../../consts/UserRole";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import PropositionStatus from "../../consts/propositionStatus/CafereoPropositionStatus";
import InvoicePeriods from "../../consts/InvoicePeriods";
import PropositionTypes from "../../consts/PropositionTypes";
import StockOwners from "../../consts/StockOwners";
import { getDisplayDetails, getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import MakerPropositionStatus from "../../consts/propositionStatus/MakerPropositionStatus";
import PropositionResultEntry from "./../../components/proposition/PropositionResultEntry.vue";
import DetailImport from "../../components/proposition/DetailImport.vue";
import PropositionInfos from "./../../components/proposition/PropositionInfos.vue";
import PropositionEntry from "./../../components/proposition/PropositionEntry.vue";
import PropositionDetailEntry from "./../../components/proposition/PropositionDetailEntry.vue";
import PropositionResultImport from "./../../components/proposition/PropositionResultImport.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import ApplicationFormExport from "../../components/proposition/ApplicationFormExport.vue";
import { comma } from "../../filter/NumberFilter";

export default {
  name: "PropositionSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ErrorGridDialog,
    PropositionResultEntry,
    DetailImport,
    PropositionInfos,
    PropositionEntry,
    PropositionDetailEntry,
    PropositionResultImport,
    ApplicationFormExport,
  },
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C020401"],
        handlingApprovalActions: ["C020402"],
        resultApprovalActions: ["C020403"],
        createActions: ["C020404"],
        updateActions: ["C020405"],
        deleteActions: ["C020406"],
        printActions: ["C020407"],
        createDetailActions: ["C020408", "C020409"],
        updateDetailActions: ["C020410"],
        deleteDetailActions: ["C020411"],
        detailAchievementInputActions: ["C020412"],
        csvActions: ["C020413"],
        supplierActions: ["M020301", "M020302"],
      },
      rules: {
        termFrom: (value) => this.termFromRules(value),
        termTo: (value) => this.termToRules(value),
        termFromFrom: (value) => this.termFromFromRules(value),
        termFromTo: (value) => this.termFromToRules(value),
        termToFrom: (value) => this.termToFromRules(value),
        termToTo: (value) => this.termToToRules(value),
        maxLength: Validation.maxLength,
      },
      validSeearchForm: null,
      shownInfo: false,
      infoMaximum: false,
      activeTab: true,
      selectedPageSize: 10,
      entrustQuantityTotal: 0,
      inspectionQuantityTotal: 0,
      salesResultsTotal: 0,
      returnQuantityTotal: 0,
      soldTotalPriceTotal: 0,
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            colId: "selection",
            width: 50,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            sortable: false,
            filter: false,
          },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        suppressColumnVirtualisation: true,
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        getRowNodeId: (data) => {
          return data.propositionId;
        },
        frameworkComponents: {
          dpSelectionFilter: SelectionFilter,
          dpBooleanFilter: BooleanFilter,
        },
      },
      baseColumnDefs: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "営業担当者", field: "salesUser" },
        { headerName: "承認可否", field: "approvalResult", type: "dpCheckmarkColumn" },
        {
          headerName: "ステータス",
          field: "propositionStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: PropositionStatus.all() },
          valueGetter: (params) => PropositionStatus.of(params.data.propositionStatus),
        },
        {
          headerName: "発注区分",
          field: "propositionType",
          filter: "dpSelectionFilter",
          filterParams: { options: PropositionTypes.all() },
          valueGetter: (params) => PropositionTypes.of(params.data.propositionType),
        },
        { headerName: "案件名", field: "propositionName" },
        { headerName: "法人名", field: "corpName", width: 300 },
        { headerName: "納品日", field: "deliveryDate", type: "dpDateColumn" },
        { headerName: "返品予定日", field: "expectedreturnDate", type: "dpDateColumn" },
        {
          headerName: "請求方式",
          field: "invoicePeriodClass",
          filter: "dpSelectionFilter",
          filterParams: { options: InvoicePeriods.all() },
          valueGetter: (params) => InvoicePeriods.of(params.data.invoicePeriodClass),
        },
        { headerName: "清算月", field: "invoicePeriodMonth", type: "dpDateColumn" },
      ],
      baseMakerColumnDefs: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "ステータス",
          field: "propositionStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerPropositionStatus.all() },
          valueGetter: (params) => MakerPropositionStatus.of(params.data.propositionStatus),
        },
        {
          headerName: "発注区分",
          field: "propositionType",
          filter: "dpSelectionFilter",
          filterParams: { options: PropositionTypes.all() },
          valueGetter: (params) => PropositionTypes.of(params.data.propositionType),
        },
        { headerName: "案件名", field: "propositionName" },
        { headerName: "納品日", field: "deliveryDate", type: "dpDateColumn" },
        { headerName: "返品予定日", field: "expectedreturnDate", type: "dpDateColumn" },
        {
          headerName: "請求方式",
          field: "invoicePeriodClass",
          filter: "dpSelectionFilter",
          filterParams: { options: InvoicePeriods.all() },
          valueGetter: (params) => InvoicePeriods.of(params.data.invoicePeriodClass),
        },
        { headerName: "清算月", field: "invoicePeriodMonth", type: "dpDateColumn" },
      ],
      inspectApprovalColumnDefs: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "営業担当者", field: "salesUser" },
        { headerName: "承認可否", field: "approvalResult", type: "dpCheckmarkColumn" },
        {
          headerName: "ステータス",
          field: "propositionStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: PropositionStatus.all() },
          valueGetter: (params) => PropositionStatus.of(params.data.propositionStatus),
        },
        {
          headerName: "発注区分",
          field: "propositionType",
          filter: "dpSelectionFilter",
          filterParams: { options: PropositionTypes.all() },
          valueGetter: (params) => PropositionTypes.of(params.data.propositionType),
        },
        { headerName: "案件名", field: "propositionName" },
        { headerName: "法人名", field: "corpName", width: 300 },
        { headerName: "納品日", field: "deliveryDate", type: "dpDateColumn" },
        { headerName: "返品予定日", field: "expectedreturnDate", type: "dpDateColumn" },
        {
          headerName: "請求方式",
          field: "invoicePeriodClass",
          filter: "dpSelectionFilter",
          filterParams: { options: InvoicePeriods.all() },
          valueGetter: (params) => InvoicePeriods.of(params.data.invoicePeriodClass),
        },
        { headerName: "清算月", field: "invoicePeriodMonth", type: "dpDateColumn" },
      ],
      gridOptionsDetail: {
        rowData: [],
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            colId: "selection",
            width: 50,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            sortable: false,
            filter: false,
          },
        ],
        getRowNodeId: (data) => data.propositionDetailId,
        rowSelection: "multiple",
        suppressCellSelection: true,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
          dpSelectionFilter: SelectionFilter,
          dpBooleanFilter: BooleanFilter,
        },
        pagination: true,
        paginationPageSize: null,
      },
      cafereoColumnDefs: [
        { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
        { headerName: "委託JANCODE", field: "janCode", filterParams: IncludeFilter },
        { headerName: "委託タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "委託商品名", field: "productName", filterParams: IncludeFilter },
        {
          headerName: "取引先名",
          field: "customerName",
          width: 300,
          filterParams: IncludeFilter,
        },
        { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
        { headerName: "委託数", field: "entrustQuantity", type: "dpNumericColumn" },
        { headerName: "販売実績数", field: "salesResults", type: "dpNumericColumn" },
        { headerName: "返品数", field: "returnQuantity", type: "dpNumericColumn" },
        { headerName: "検品数", field: "inspectionQuantity", type: "dpNumericColumn" },
        {
          headerName: "在庫所有元",
          field: "stockOwner",
          valueGetter: (params) => StockOwners.of(params.data.stockOwner),
        },
        { headerName: "納品先", field: "delivery1" },
        { headerName: "返送先", field: "supplierDelivery1" },
        { headerName: "メーカー検品数", field: "makerInspectionQuantity", type: "dpNumericColumn" },
        { headerName: "検品差異", field: "inspectionDifference", type: "dpCheckmarkColumn" },
        { headerName: "売立金額", field: "soldTotalPrice", type: "dpNumericColumn" },
        { headerName: "受注ID", field: "orderId" },
      ],
      makerColumnDefs: [
        { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
        { headerName: "委託JANCODE", field: "janCode" },
        { headerName: "委託タイトル", field: "title" },
        { headerName: "委託商品名", field: "productName" },
        {
          headerName: "委託先取引先名",
          field: "customerName",
          width: 300,
        },
        { headerName: "卸掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "仕入掛率", field: "purchaseRate", type: "dpPercentColumn" },
        { headerName: "委託数", field: "entrustQuantity", type: "dpNumericColumn" },
        { headerName: "販売実績数", field: "salesResults", type: "dpNumericColumn" },
        { headerName: "返品数", field: "returnQuantity", type: "dpNumericColumn" },
        { headerName: "検品数", field: "inspectionQuantity", type: "dpNumericColumn" },
        { headerName: "返送先", field: "supplierDelivery1" },
        { headerName: "メーカー検品数", field: "makerInspectionQuantity", type: "dpNumericColumn" },
        { headerName: "検品差異", field: "inspectionDifference", type: "dpCheckmarkColumn" },
      ],
      printColumnDefs: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        {
          headerName: "在庫所有元",
          editable: true,
          field: "stockOwner",
          type: "EditableColumn",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: StockOwners.all().map((stockOwner) => {
              return stockOwner.text;
            }),
          },
        },
      ],
      errorColmuns: [
        { headerName: "委託・消化仕入ID", field: "propositionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorColmunDetails: [
        { headerName: "委託・消化仕入明細ID", field: "propositionDetailId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],

      propositionRecords: [],
      propositionDetailRecords: [],

      selectionRows: [],
      selectionDetailRows: [],
      searchModel: {
        termFrom: null,
        termTo: null,
        termFromFrom: null,
        termFromTo: null,
        termToFrom: null,
        termToTo: null,
        propositionDetailId: null,
      },
      editDialog: false,
      editDetailDialog: false,
      resultEntryDialog: false,
      printDialog: false,
      propositionModel: {
        createNew: null,
      },
      propositionDetailModel: {
        createNew: null,
      },
      resultEntryModel: {
        createNew: false,
        createRemaining: false,
        rows: [],
      },
      applicationFormDialog: false,
      propositionList: [],
      propositionDetailList: [],
      importDialog: { show: false, width: "800px" },
      resultImportDialog: { show: false, width: "800px" },
      gridStyle: { height: "95%" },
    };
  },
  watch: {
    selectedPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.gridOptionsDetail.api.paginationSetPageSize(size);
    },
    selectedRow() {
      if (this.selectedRow) {
        this.onSearchClickDetails();
      }
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.gridOptionsDetail.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = (value - 120.5) * 0.8 + "px";
    },
  },
  filters: { comma },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    this.gridOptionsDetail.paginationPageSize = this.globalPageSize;
    if (this.allowedAction(this.constants.cafereoActions)) this.gridOptions.columnDefs.push(...this.baseColumnDefs);
    if (this.allowedAction(this.constants.supplierActions))
      this.gridOptions.columnDefs.push(...this.baseMakerColumnDefs);
    const setColumnDefs = (injectionColumnDefs) => {
      this.gridOptionsDetail.columnDefs = this.gridOptionsDetail.columnDefs.concat(injectionColumnDefs);
    };
    if (this.allowedAction(this.constants.cafereoActions)) setColumnDefs(this.cafereoColumnDefs);
    if (this.allowedAction(this.constants.supplierActions)) setColumnDefs(this.makerColumnDefs);
  },
  mounted() {
    this.onSearchClick();
    this.gridStyle.height = (this.gridHeightSize - 120.5) * 0.8 + "px";
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetailRow() {
      return this.selectionDetailRows.length === 1 ? this.selectionDetailRows[0] : null;
    },
    availableRole() {
      return RoleOfType(this.propositionModel.corpinfo?.corptype);
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let detailColumns = this.gridOptions.columnApi
        .getAllColumns()
        .filter((column) => {
          return !column.colDef.hide;
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          if (
            coldef.field == "createDatetime" ||
            coldef.field == "deliveryDate" ||
            coldef.field == "expectedreturnDate" ||
            coldef.field == "invoicePeriodMonth"
          ) {
            coldef.type = "dpFullDateColumn";
          }
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.propositionId, detailColumns, this.gridOptions.api);
    },
    selectedDetailDetails() {
      if (!this.selectedDetailRow) {
        return [];
      }
      return getDisplayDetails(
        this.selectedDetailRow.propositionDetailId,
        this.gridOptionsDetail.api,
        this.gridOptionsDetail.columnApi
      );
    },
    selectPropositionId() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.propositionId;
    },
    selectPropositionDetailId() {
      if (!this.selectedDetailRow) {
        return null;
      }
      return this.selectedDetailRow.propositionDetailId;
    },
    isSanction() {
      if (
        this.selectionRows &&
        this.selectionRows.length > 0 &&
        this.allowedAction(this.constants.handlingApprovalActions)
      ) {
        // 金額毎にフラグを立てる
        let topSanction = false;
        this.selectionRows.forEach((row) => {
          if (row.totalPrice >= 5000000) {
            topSanction = true;
          }
        });

        // ロールとフラグをチェック
        const role = this.$store.getters["security/loggedInUser"].role;
        if (role == "CR:TOP" || role == "CR:ST2") {
          return true;
        } else if ((role == "CR:SS2" || role == "CR:SA2") && !topSanction) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    clearFiltersDetail() {
      this.gridOptionsDetail.api.setFilterModel(null);
      this.gridOptionsDetail.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    termFromRules(value) {
      if (value == null || this.searchModel.termTo == null) return true;
      if (moment(value).isAfter(this.searchModel.termTo)) return "返品予定日より前の日付を指定してください";
      return true;
    },
    termToRules(value) {
      if (value == null || this.searchModel.termFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.termFrom)) return "納品日より後の日付を指定してください";
      return true;
    },
    termFromFromRules(value) {
      if (value == null || this.searchModel.termFromTo == null) return true;
      if (moment(value).isAfter(this.searchModel.termFromTo)) return "納品日Toより前の日付を指定してください";
      return true;
    },
    termFromToRules(value) {
      if (value == null || this.searchModel.termFromFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.termFromFrom)) return "納品日Fromより後の日付を指定してください";
      return true;
    },
    termToFromRules(value) {
      if (value == null || this.searchModel.termToTo == null) return true;
      if (moment(value).isAfter(this.searchModel.termToTo)) return "返品予定日Toより前の日付を指定してください";
      return true;
    },
    termToToRules(value) {
      if (value == null || this.searchModel.termToFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.termToFrom)) return "返品予定日Fromより後の日付を指定してください";
      return true;
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionDetailRows = [];
        const params = { ...this.searchModel };
        const response = await this.$store.dispatch("proposition/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = this.allowedAction(this.constants.supplierActions) ? "委託・消化仕入一覧" : "委託・消化商品管理";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.propositions).length === 0) {
          let title = this.allowedAction(this.constants.supplierActions) ? "委託・消化仕入一覧" : "委託・消化商品管理";
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.propositions || []);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.propositionList = result.propositions;

        this.gridOptionsDetail.api.setRowData([]);
        this.propositionDetailList = [];
        this.selectionRows = [];
      } catch (error) {
        console.error("PropositionSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onExportClick() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `委託・消化仕入一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onExportClickDetails() {
      let allColumnIds = [];
      this.gridOptionsDetail.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptionsDetail.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionDetailRows.length > 0,
        fileName: `委託・消化仕入明細一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onEntryClick() {
      this.propositionModel = {
        createNew: true,
      };
      this.editDialog = true;
    },
    onEditClick() {
      this.propositionModel = {
        createNew: false,
        ...this.selectedRow,
      };
      this.editDialog = true;
    },
    async onEntrySubmit() {
      this.onSearchClick();
      this.editDialog = false;
    },
    async onEditSubmit(updateModel) {
      this.gridOptions.api.applyTransaction({ update: [updateModel] });
      this.selectionRows = [];
      this.selectionRows.push(updateModel);
      this.editDialog = false;
    },
    onDialogClose() {
      this.editDialog = false;
    },
    async onRemoveClick() {
      const ok = await this.$dialog.confirm({
        title: "委託・消化仕入一覧",
        text: `選択された委託・消化仕入を削除します`,
      });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectionRows.forEach((row) => {
            updateModels.push({
              propositionId: row.propositionId,
              updateDatetime: row.updateDate,
            });
          });
          var requestContents = {
            propositions: updateModels,
          };
          const result = await this.$store.dispatch("proposition/remove", requestContents);

          let error = result.data?.header;

          const removeRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    propositionId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 更新分のレコード変更
              if (result.data.contents) {
                this.selectionRows.forEach((row) => {
                  result.data.contents.propositions.forEach((proposition) => {
                    if (proposition == row.propositionId) {
                      removeRow.push(row);
                    }
                  });
                });
              }
              if (removeRow.length > 0) this.gridOptions.api.applyTransaction({ remove: removeRow });
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "委託・消化仕入削除", records: errorRows });
                this.$dialog.notify.error(`委託・消化仕入削除に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`委託・消化仕入を削除しました`, { timeout: 2300 });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PropositionSearch::onRemoveClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      if (this.selectionRows.length != 1) {
        this.gridOptionsDetail.api.setRowData([]);
      }
    },
    onBtnApproval() {
      this.$refs.confirmGrid.open({
        title: "取り扱い承認",
        columns: this.baseColumnDefs,
        records: this.selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            records.forEach((row) => {
              updateModels.push({
                propositionId: row.propositionId,
                updateDatetime: row.updateDate,
              });
            });
            var requestContents = {
              propositions: updateModels,
            };
            const result = await this.$store.dispatch("proposition/approval", requestContents);

            let error = result.data?.header;

            const updateRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      propositionId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                if (result.data?.contents?.propositions) {
                  this.selectionRows.forEach((row) => {
                    result.data.contents.propositions.forEach((proposition) => {
                      if (proposition.propositionId == row.propositionId) {
                        row.approvalResult = true;
                        row.propositionStatus = proposition.status;
                        row.updateDate = proposition.updateDate;
                        updateRow.push(row);
                      }
                    });
                  });
                }
                if (updateRow.length > 0) this.gridOptions.api.applyTransaction({ update: updateRow });
                if (errorRows.length > 0) {
                  this.$refs.bulkErrorGrid.open({ title: "取り扱い承認", records: errorRows });
                  this.$dialog.notify.error(`取り扱い承認に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`選択された委託・消化仕入データを取り扱い承認しました`, {
                    timeout: 2300,
                  });
                  this.$refs.confirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("PropositionSearch::onBtnApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onBtnInspectApproval() {
      this.$refs.inspectConfirmGrid.open({
        title: "検品結果承認",
        columns: this.inspectApprovalColumnDefs,
        records: this.selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            records.forEach((row) => {
              updateModels.push({
                propositionId: row.propositionId,
                updateDatetime: row.updateDate,
              });
            });
            var requestContents = {
              propositions: updateModels,
            };
            const result = await this.$store.dispatch("proposition/complete", requestContents);

            let error = result.data?.header;

            const updateRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      propositionId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                if (result.data?.contents?.propositions) {
                  this.selectionRows.forEach((row) => {
                    result.data.contents.propositions.forEach((proposition) => {
                      if (proposition.propositionId == row.propositionId) {
                        row.propositionStatus = proposition.status;
                        row.updateDate = proposition.updateDate;
                        updateRow.push(row);
                      }
                    });
                  });
                }
                if (updateRow.length > 0) this.gridOptions.api.applyTransaction({ update: updateRow });
                if (errorRows.length > 0) {
                  if (updateRow.length > 0)
                    this.$refs.inspectConfirmGrid.gridOptions.api.applyTransaction({ remove: updateRow });
                  this.$refs.bulkErrorGrid.open({ title: "検品結果承認", records: errorRows });
                  this.$dialog.notify.error(`検品結果承認に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`選択された委託・消化仕入データを検品結果承認しました`, {
                    timeout: 2300,
                  });
                  this.$refs.inspectConfirmGrid.close();
                }
                if (records.length == 1) this.onSearchClickDetails();
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("PropositionSearch::onBtnInspectApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    onResultImportDialogClose() {
      this.resultImportDialog.show = false;
    },
    onBtnResultImport() {
      this.resultImportDialog.show = true;
    },
    onResultImportComplete() {
      this.resultImportDialog.show = false;
      this.onSearchClick();
    },
    onPrintDialogClose() {
      this.printDialog = false;
    },
    onBtnApplicationForm() {
      this.printDialog = true;
    },
    async onSearchClickDetails() {
      try {
        this.loadingOn();
        this.selectionDetailRows = [];
        const params = { propositionId: this.selectPropositionId };
        const response = await this.$store.dispatch("propositionDetail/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = this.allowedAction(this.constants.supplierActions) ? "委託・消化仕入一覧" : "委託・消化商品管理";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        let tmpPropositionDetails = null;
        if (this.allowedAction(this.constants.cafereoActions)) {
          tmpPropositionDetails = result.propositionDetails;
        } else {
          tmpPropositionDetails = result.propositionDetail;
        }
        if (Object.keys(tmpPropositionDetails).length === 0) {
          let title = this.allowedAction(this.constants.supplierActions) ? "委託・消化仕入一覧" : "委託・消化商品管理";
          this.$dialog.warning({
            title: title,
            text: `明細は0件です。`,
            actions: ["OK"],
          });
        }
        if (this.allowedAction(this.constants.cafereoActions)) {
          this.gridOptionsDetail.api.setRowData(result.propositionDetails || []);
        } else {
          this.gridOptionsDetail.api.setRowData(result.propositionDetail || []);
        }
        const allColumnIds = this.gridOptionsDetail.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.gridOptionsDetail.columnApi.autoSizeColumns(allColumnIds);
        this.propositionDetailList = result.propositionDetails;
        this.onfilterChanged();
      } catch (error) {
        console.error("PropositionSearch::onSearchClickDetails", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onEntryClickDetails() {
      this.propositionDetailModel = {
        createNew: true,
      };
      this.editDetailDialog = true;
    },
    onEditClickDetails() {
      this.propositionDetailModel = {
        createNew: false,
        ...this.selectedDetailRow,
      };
      this.editDetailDialog = true;
    },
    async onEntrySubmitDetails() {
      this.onSearchClickDetails();
      this.editDetailDialog = false;
    },
    async onEditSubmitDetails() {
      this.onSearchClickDetails();
      this.editDetailDialog = false;
    },
    onDialogCloseDetails() {
      this.editDetailDialog = false;
    },
    async onRemoveClickDetails() {
      const ok = await this.$dialog.confirm({
        title: "委託・消化仕入明細一覧",
        text: `選択された委託・消化仕入明細を削除します`,
      });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectionDetailRows.forEach((row) => {
            updateModels.push({
              propositionDetailId: row.propositionDetailId,
              updateDate: row.updateDate,
            });
          });
          var requestContents = {
            propositionDetails: updateModels,
          };
          const result = await this.$store.dispatch("propositionDetail/remove", requestContents);

          let error = result.data?.header;

          const removeRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    propositionDetailId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 更新分のレコード変更
              if (result.data.contents) {
                this.selectionDetailRows.forEach((row) => {
                  result.data.contents.propositionDetails.forEach((proposition) => {
                    if (proposition == row.propositionDetailId) {
                      removeRow.push(row);
                    }
                  });
                });
              }
              if (removeRow.length > 0) this.gridOptionsDetail.api.applyTransaction({ remove: removeRow });
              if (errorRows.length > 0) {
                this.$refs.detailBulkErrorGrid.open({ title: "委託・消化仕入明細削除", records: errorRows });
                this.$dialog.notify.error(`委託・消化仕入明細削除に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`委託・消化仕入明細を削除しました`, {
                  timeout: 2300,
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PropositionSearch::onRemoveClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onSelectionChangedDetails() {
      this.selectionDetailRows = this.gridOptionsDetail.api.getSelectedRows();
      console.log(this.selectionDetailRows);
    },
    onfilterChanged() {
      this.entrustQuantityTotal = 0;
      this.inspectionQuantityTotal = 0;
      this.salesResultsTotal = 0;
      this.returnQuantityTotal = 0;
      this.soldTotalPriceTotal = 0;
      if (this.allowedAction(this.constants.cafereoActions)) {
        let rowData = [];
        this.gridOptionsDetail.api.forEachNodeAfterFilter((node) => rowData.push(node.data));
        for (let row of rowData) {
          this.entrustQuantityTotal += row.entrustQuantity ? row.entrustQuantity : 0;
          this.inspectionQuantityTotal += row.inspectionQuantity ? row.inspectionQuantity : 0;
          this.salesResultsTotal += row.salesResults ? row.salesResults : 0;
          this.returnQuantityTotal += row.returnQuantity ? row.returnQuantity : 0;
          this.soldTotalPriceTotal += row.soldTotalPrice ? row.soldTotalPrice : 0;
        }
      }
    },
    onBtnResultEntry() {
      this.resultEntryModel.createNew = true;
      this.resultEntryModel.createRemaining = false;
      this.resultEntryModel.rows = this.selectionDetailRows;

      this.resultEntryDialog = true;
      if (this.$refs.propositionResultEntry) this.$refs.propositionResultEntry.onOpen(this.resultEntryModel);
    },
    async onResultEntrySubmit(rowData) {
      let updateRow = [];
      // 更新分のレコード変更
      this.selectionDetailRows.forEach((row) => {
        rowData.forEach((proposition) => {
          if (proposition.propositionDetailId == row.propositionDetailId) {
            row.salesResults = proposition.salesResults;
            row.returnQuantity = proposition.returnQuantity;
            row.updateDate = proposition.updateDate;

            updateRow.push(row);
          }
        });
      });
      if (updateRow.length > 0) this.gridOptionsDetail.api.applyTransaction({ update: updateRow });
      this.resultEntryDialog = false;
    },
    onResultDialogClose() {
      this.resultEntryDialog = false;
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportDialogClose() {
      this.importDialog.show = false;
    },
    onImportComplete() {
      this.importDialog.show = false;
      this.onSearchClick();
    },
  },
};
</script>
